import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class VolumenUno {
  static selector = ".volume-uno";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.imageTitleNode = block.querySelector(
      ".volume-uno__title__image .image"
    );
    this.imageAndCreditsNode = block.querySelector(".volume-uno__title__image");
    this.imageNodes = block.querySelectorAll(".volume-uno__images__image");
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, 0, 1),
      0,
      1
    );
    console.log(progress);

    this.imageAndCreditsNode.style.transform = `translateY(${50 * progress}%)`;
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await new CustomImage(this.imageTitleNode, {
        src: this.imageTitleNode.dataset.src,
        alt: "",
      }).onReady();

      await Promise.all(
        [...this.imageNodes].map(async (node) => {
          await new CustomImage(node, {
            src: node.dataset.src,
            alt: "",
          }).onReady();
        })
      );

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
