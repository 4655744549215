import "./style.css";
import layout from "./layout.html";
import { getRaf } from "@app";

const BASE_PX_PER_SECOND = 50;

export default class Marquee {
  static selector = ".marquee";

  constructor(block, text) {
    this.block = block;
    this.block.innerHTML = layout;
    this.inner = block.querySelector(".marquee__inner");
    this.contentNode = block.querySelector(".marquee__content");
    this.contentNode.innerHTML = text;
  }

  updateContentClones = () => {
    while (
      this.inner.scrollWidth - this.contentTemplateWidth <
      this.innerWidth
    ) {
      this.inner.appendChild(this.contentTemplate.cloneNode(true));
    }
  };

  onAnimationFrame = (t, dt) => {
    const deltaX = (dt / 1000) * BASE_PX_PER_SECOND;

    this.x -= deltaX;

    if (this.x < -this.contentTemplateWidth) {
      this.x += this.contentTemplateWidth;
    }

    this.inner.style.transform = `translateX(${this.x}px)`;
  };

  // Block Lifecycle Methods
  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.contentTemplate = this.inner
        .querySelector(".marquee__content")
        .cloneNode(true);

      this.contentTemplateWidth =
        this.inner.querySelector(".marquee__content").offsetWidth;
      this.innerWidth = this.inner.offsetWidth;
      this.x = 0;

      this.updateContentClones();

      this.raf = getRaf();
      this.raf.register("marquee", this.onAnimationFrame);

      resolve();
    });
  };

  onResize = () => {
    this.contentTemplateWidth =
      this.inner.querySelector(".marquee__content").offsetWidth;
    this.innerWidth = this.inner.offsetWidth;
    this.x = 0;

    this.updateContentClones();
  };
  onDestroy = () => {};
}
