import "./style.css";
import layout from "./layout.html";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class OpenCall {
  static selector = ".open-call";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.decoratorNode = block.querySelector(".open-call__title__decorator");
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, 0, 1),
      0,
      1
    );

    this.decoratorNode.style.transform = `translateY(${50 * progress}%)`;
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
