import "./style.css";

export default class TextWithDynamicFontSize {
  static selector = ".text-with-dynamic-font-size";

  constructor(block) {
    this.block = block;
    this.rowsNode = block.querySelector(".text-with-dynamic-font-size__rows");
    this.rowNodes = block.querySelectorAll(
      ".text-with-dynamic-font-size__rows__row"
    );
    this.resizeTimeout = null;
  }

  resetRowsFontSize = () => {
    this.rowNodes.forEach((row) => {
      row.style.fontSize = null;
      row.style.lineHeight = null;
    });
  };

  updateRowsFontSize = () => {
    this.resetRowsFontSize();

    let i = 0;
    /*while (this.rowsNode.offsetWidth !== this.block.offsetWidth) {
      i =
        this.rowsNode.offsetWidth > this.block.offsetWidth
          ? i - 0.01
          : i + 0.01;

      this.block.style.fontSize = `${this.originalRem + i}rem`;
      this.block.style.lineHeight = `${this.originalRem + i}rem`;
    }*/
  };

  getFontSizeInRem = () => {
    const fontSizeInPX = parseFloat(
      window.getComputedStyle(this.block).fontSize
    );

    const oneRemInPx = parseFloat(
      window.getComputedStyle(document.body).fontSize
    );

    return Math.round(fontSizeInPX / oneRemInPx);
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      this.onResize();

      resolve();
    });
  };

  onResize = () => {
    this.originalRem = this.getFontSizeInRem();
    this.updateRowsFontSize();
  };
}
