import "./style.css";
import { getObserver } from "@app";

export default class AnimatedText {
  static selector = ".animated-text";

  constructor(block) {
    this.block = block;

    this.splitted = this.block.dataset.splitted;
    this.transitionDuration = 1200;
    this.entranceDelegated = block.dataset.entranceDelegated;
    this.backgroundImage = block.dataset.backgroundImage;
    this.hiding = false;
    this.firstRowDelay = block.dataset.firstRowDelay;
    this.maxRows = this.block.dataset.maxRows
      ? parseInt(this.block.dataset.maxRows)
      : null;
    this.rowDelay = this.block.dataset.rowDelay
      ? parseFloat(this.block.dataset.rowDelay)
      : 0;
  }

  show = () => {
    return new Promise((resolve) => {
      this.textRows.forEach((row, i) => {
        row.style.transition = `none`;
      });
      this.block.classList.remove("animated-text--out-top");
      window.setTimeout(() => {
        this.textRows.forEach((row, i) => {
          row.style.transition = null;
        });

        this.hiding = false;
        /*
        const observer = getObserver();
        observer.unregister(this.block);
        */

        if (this.textRows.length === 0) {
          this.block.querySelectorAll(".animated-text__row");
        }

        this.textRows.forEach((row, i) => {
          row.style.transitionDelay = `${
            this.firstRowDelay ? this.rowDelay * (i + 4) : this.rowDelay * i
          }s`;
        });
        this.block.classList.add("animated-text--in-view");
      }, 20);

      setTimeout(() => {
        resolve();
      }, this.transitionDuration * 0.3);
    });
  };

  hide = () => {
    this.hiding = true;

    this.textRows.forEach((row) => {
      row.style.transitionDelay = null;
    });
    this.block.classList.remove("animated-text--in-view");
  };

  hideToTop = () => {
    this.hiding = true;
    this.textRows.forEach((row) => {
      row.style.transitionDelay = null;
    });

    this.block.classList.remove("animated-text--in-view");
    this.block.classList.add("animated-text--out-top");
  };

  wordsCount = (str) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");
    return str.split(" ").length;
  };

  addBckgroundImageToRows = () => {
    this.textRows.forEach((row) => {
      row.style.backgroundImage = `url(${this.backgroundImage})`;
    });
  };

  getRow = (text) => {
    this.block.textContent = text;

    while (this.block.scrollWidth > this.block.offsetWidth) {
      let lastIndex =
        this.wordsCount(text) > 1 ? text.lastIndexOf(" ") : text.length - 1;
      text = text.substring(0, lastIndex);
      this.block.textContent = text;
    }

    const result = this.block.textContent;
    return result;
  };

  setupRows = () => {
    if (this.splitted === "true") {
      this.textRows = this.block.querySelectorAll(".animated-text__row");
      if (this.backgroundImage) {
        this.addBckgroundImageToRows();
      }
      return;
    }

    this.block.style.overflow = "hidden";
    let text = this.block.textContent;
    let textRows = [];

    while (
      (text.length > 0 && this.maxRows && textRows.length < this.maxRows) ||
      (text.length > 0 && !this.maxRows)
    ) {
      let newRow = this.getRow(text);
      textRows.push(newRow);
      text = text.substring(newRow.length, text.length);
    }

    this.block.innerHTML = "";
    textRows.forEach((row, i) => {
      let rowWrapper = document.createElement("span");
      rowWrapper.classList.add("animated-text__wrapper");

      let span = document.createElement("span");
      span.classList.add("animated-text__row");

      span.textContent = row;
      rowWrapper.appendChild(span);
      this.block.appendChild(rowWrapper);
    });

    this.textRows = this.block.querySelectorAll(".animated-text__row");
    if (this.backgroundImage) {
      this.addBckgroundImageToRows();
    }
    this.block.style.overflow = null;
  };

  onLoaderHidden = () => {
    if (this.entranceDelegated === "onLoad") {
      this.show();
    }
  };

  onPageChangeComplete = () => {
    return new Promise(async (resolve, reject) => {
      await this.onComplete();
      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.completed = true;
      if (!this.entranceDelegated) {
        this.block.dataset.intersectionRatio = 0.8;
        const observer = getObserver();
        observer.register(
          this.block.dataset.instanceIndex,
          this.show,
          this.block
        );
      }

      resolve();
    });
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      this.vw = window.innerWidth;
      this.setupRows();

      resolve();
    });
  };

  onResize = () => {
    if (this.vw && window.innerWidth === this.vw) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.setupRows();
    }, 400);
  };
}
