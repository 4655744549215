import "./style.css";
import layout from "./layout.html";

export default class Intro {
  static selector = ".intro";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {};

  onResize = () => {};
}
